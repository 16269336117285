import styled from 'styled-components';
import { Pill } from 'components/atoms/button';
import Link from 'components/atoms/link';
import {
  fontMediumLarge,
  fontSmall,
  fontMediumSmall,
} from 'components/styles/typography';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.spacing.DOUBLE_BASE_SPACING} ${theme.spacing.BASE_SPACING} ${theme.spacing.BASE_SPACING}`};
`;

export const SignupWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing.customSpacing('24px')} 0`};
  color: ${({ theme }) => theme.new.content};
  ${fontMediumSmall};
`;

export const StyledPill = styled(Pill)`
  width: ${({ theme }) => theme.spacing.customSpacing('230px')};
`;

export const LoginHeader = styled.h1`
  ${fontMediumLarge};
  color: ${({ theme }) => theme.new.content};
  margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const InfoMessage = styled.p`
  ${fontMediumSmall};
  color: ${({ theme }) => theme.new.content};
  padding-bottom: ${({ theme }) => theme.spacing.customSpacing('8px')};
`;

export const ErrorMessage = styled.p`
  ${fontSmall};
  color: ${({ theme }) => theme.new.error.background};
`;

export const LoginHeaderWrapper = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing.customSpacing('44px')} 0 ${theme.spacing.BASE_SPACING} ${
      theme.spacing.BASE_SPACING
    }`};
`;

export const ForgotPassword = styled.div<{ error: boolean }>`
  ${fontSmall};
  background-color: transparent;
  color: ${({ theme }) => theme.new.information.background};
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.BASE_SPACING};
  :hover {
    text-decoration: underline;
  }
  ${({ theme, error }) =>
    error ? `padding-top: ${theme.spacing.HALF_BASE_SPACING}` : ''}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledLink = styled(Link)`
  background-color: transparent;
  color: ${({ theme }) => theme.new.information.background};
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing.customSpacing('2px')};
  ${fontMediumSmall};
  :hover {
    text-decoration: underline;
  }
`;
