import styled from 'styled-components';
import {
  fontSmall,
  fontMediumLarge,
  fontMediumSmall,
} from 'components/styles/typography';
import { Pill } from 'components/atoms/button';

export const FormWrapper = styled.form`
  padding: ${({ theme }) =>
    `${theme.spacing.DOUBLE_BASE_SPACING} ${theme.spacing.BASE_SPACING} ${theme.spacing.BASE_SPACING}`};
`;

export const ForgetPasswordMessage = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
  color: ${({ theme }) => theme.new.content};
  ${fontMediumSmall};
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${fontMediumLarge};
  color: ${({ theme }) => theme.new.content};
  gap: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  cursor: pointer;
  padding: ${({ theme }) =>
    `${theme.spacing.customSpacing('44px')} 0 0 ${theme.spacing.BASE_SPACING}`};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PillWrapper = styled(ButtonsWrapper)`
  margin-bottom: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
`;

export const StyledPill = styled(Pill)`
  ${fontMediumLarge}
  margin-top: ${({ theme }) => theme.spacing.customSpacing('15px')};
  width: ${({ theme }) => theme.spacing.customSpacing('230px')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const ErrorMessage = styled.p`
  ${fontSmall};
  color: ${({ theme }) => theme.new.error.background};
`;
